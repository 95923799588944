const devConfig = {
  serverUrl: 'http://localhost:8000',
};

const environments = {
  production: {
    serverUrl: 'https://meep-ai-fccb9b5fae73.herokuapp.com',
  },
  development: devConfig,
  test: devConfig,
};

export default environments;
